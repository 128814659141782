.container{
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  gap: 20px;
}

.title{
  font-size: 2rem;
  font-weight: 900;
  text-wrap: nowrap;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  position: relative;
  width: 500px;
  align-items: center;
}
.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
}




.form__field {
    font-family: inherit;
    width: 100%;
    border: none;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 17px;
    color: #fff;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    resize: none;
  }
  
  .form__field::placeholder {
    color: transparent;
  }
  
  .form__field:placeholder-shown ~ .form__label {
    font-size: 17px;
    cursor: text;
    top: 29px;
  }
  
  
  .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 17px;
    color: #9b9b9b;
    pointer-events: none;
  }
  
  .form__field:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #671199, #ac38ef);
    border-image-slice: 1;
  }
  
  .form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 17px;
    color: #ac38ef;
    font-weight: 700;
  }
  
  textarea{
    height: 2.2rem;
  }

  .button {
    cursor: pointer;
    position: relative;
    padding: 10px 24px;
    font-size: 18px;
    color: #c041ff;
    border: 2px solid #c552ff;
    border-radius: 34px;
    background-color: transparent;
    font-weight: 600;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
    overflow: hidden;
    width: fit-content;
  }
  
  .button::before {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: inherit;
    scale: 0;
    z-index: -1;
    background-color: #c34aff;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .button:hover::before {
    scale: 3;
  }
  
  .button:hover {
    color: #212121;
    scale: 1.1;
    box-shadow: 0 0px 20px rgba(193, 163, 98,0.4);
  }
  
  .button:active {
    scale: 1;
  }
  
  
  