:root {
    --topo: url("/public/assets/topography.svg");
  }
  
  .linha{
    width: 100%;
    height: 1px;
    background-color: transparent;
  }

  .topo_bg {
    width: 100%;
    height: fit-content;
    background-image: var(--topo);
    background-repeat: repeat;
    padding: 3.5rem;
    display: flex;
    justify-content: center;
  }

  .card {
    width: 100%;
    border-radius: 13px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid #2e2e2e;
    padding: 2rem 3rem 2rem 3rem;
  }

  .break{
    width: 100%;
    height: 2px;
    background-color: #2d2d2d;
    margin: 2rem 0 2rem 0;
  }

  @media (max-width: 768px) {
    .topo_bg {
      display: none; /* Esconde a classe topo_bg em telas menores que 768px */
    }
  }