.header{
    z-index: 999 !important;
    position: fixed;
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #0b0b0b;
    overflow: hidden;
    top: 0;
}

.menu{
    display: flex;
    width: fit-content;
    justify-content: space-evenly;
    gap: 4rem;
}

.menu_item:first-child{
    margin: 0 0 0 2rem;
}
.menu_item:last-child{
    margin: 0 2rem 0 0;
}


.menu_item{
    font-size: 1.2rem;
    text-decoration: none;
    color: white;
    white-space: nowrap;
}

