


.card{
  background-color: #2e2e2e50;
  width: 500px;
  min-height: 550px;
  
  border-radius: 1rem;
  overflow: hidden;
  padding: 2rem;
  border: solid #2e2e2e 1px;
  transition: all 0.1s ease 0s;
}

.card:hover{
  border-color: #343434;
}

.img_card img{
  margin: auto;
  width: 100%;
  height: 250px;
  margin-bottom: 1rem;
  border-radius: 1rem;
  transition: all 0.1s ease 0s;
}


.title_section{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title_section p{
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  word-wrap: break-word;
}

.title_section span{
  margin-left: 1rem;
  margin-right: 1rem;
  height: 2px;
  width: 100%;
  background-color: #2e2e2e;
}

.img_card{
  display: flex;
  justify-content: center;
}


.desc{
  width: 100%;
  margin-top: 1rem;
  font-size: 1.2rem;
}

.teste{
  font-size: 2rem;
}

.icons span{
  display: flex;
  background-color: #2e2e2e;
  height: auto;
  width: 1px;
}

.icons{
  display: flex;
  flex-direction: row;
}

.icons a{
  transition: all 0.2s ease 0s;
}

.icons a:hover{
  opacity: .5;
}
