@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Space+Grotesk:wght@300..700&display=swap');
*{
  font-family: "Space Grotesk", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: white;
  font-weight:normal;
}

body{
  background-color: #0b0b0b;
  overflow-x: hidden;
}

a{
  text-decoration: none;
}
