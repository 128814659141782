:root {
  --fundo: #585858;
}

.container {
  top: auto;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.container::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle, var(--fundo) 1px, transparent 1px),
    radial-gradient(circle, var(--fundo) 1.2px, transparent 1.2px);
  background-size: 40px 40px;
}

.animated_gradient {
  background: linear-gradient(
    40deg,
    #8c1df3 20%,
    #f714d1 30%,
    #9d01cd 70%,
    #621aaf 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 500% auto;
  font-weight: bold;
  animation: textShine 5s ease-in-out infinite alternate;
}

.title {
  font-weight: bold;
  font-size: 5rem;
}

.subtitle {
  font-weight: 100;
  font-size: 2rem;
}

.typewriter {
  font-weight: bold;
  font-size: 3rem;
}

.text_parent {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* Telas grandes (desktops e tablets maiores) */
@media (min-width: 1024px) {
  .title {
    font-size: 5rem; /* Tamanho original */
  }

  .subtitle {
    font-size: 1.5rem; /* Tamanho original */
  }

  .typewriter {
    font-size: 2rem; /* Tamanho original */
  }
}

/* Telas médias (tablets e laptops menores) */
@media (max-width: 1024px) {
  .title {
    font-size: 4rem;
  }

  .subtitle {
    font-size: 1.75rem;
  }

  .typewriter {
    font-size: 2.0rem;
  }
}

/* Telas pequenas (smartphones maiores) */
@media (max-width: 768px) {
  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .typewriter {
    font-size: 1rem;
  }
}

/* Telas muito pequenas (smartphones menores) */
@media (max-width: 480px) {
  .title {
    font-size: 2.3rem;
    text-align: center;
  }

  .subtitle {
    font-size: 1rem;
    text-align: center;
  }

  .typewriter {
    font-size: 1.5rem;
    text-align: center;
  }

  .container {
    padding: 1rem;
  }
}

