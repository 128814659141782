nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2.5rem; 
  }
  
  nav a img {
    width: 2.25rem; 
  }
  